<template>
  <div>
    <b-card>
      <b-card-header>
        <strong>
          <feather-icon icon="LayersIcon" />
          {{ $t('classification.edit.classificationData') }}
        </strong>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <!-- User Info: Input Fields -->
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <!-- Request Id -->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.columns.requestId')"
                >
                  <b-form-input
                    v-model="classificationData.solicitudId"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- Request type field -->
              <b-col
                v-if="classificationData.clasificacionSolicitudTipo"
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.columns.requestType')"
                >
                  <b-form-input
                    v-model="classificationData.clasificacionSolicitudTipo.nombre"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!-- Customer field -->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Cliente"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('classification.edit.columns.customer')"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="classificationData.clienteId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="texto"
                      :options="customers"
                      :reduce="option => option.id"
                      disabled
                      @close="refetchVendors(classificationData.clienteId)"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Vendor field-->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.columns.supplier')"
                >
                  <v-select
                    v-model="classificationData.proveedorId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="vendors"
                    :reduce="option => option.id"
                    disabled
                  />
                </b-form-group>
              </b-col>
              <!--PartNumber field-->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Numero de parte"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('classification.edit.columns.numberPart')"
                  >
                    <b-form-input
                      v-model="classificationData.numeroParte"
                      :state="getValidationState(validationContext)"
                      disabled
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Description field-->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="validationContext"
                  name="Descripcion"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('classification.edit.description')"
                  >
                    <b-form-input
                      v-model="classificationData.descripcion"
                      :state="getValidationState(validationContext)"
                      :disabled="!permissions || classificationEstado2 "
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Weight field-->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.columns.peso')"
                >
                  <b-form-input
                    v-model="classificationData.peso"
                    type="number"
                    :disabled="!permissions || classificationTipo1"
                  />
                </b-form-group>
              </b-col>
              <!--Fraccion Arancelaria field-->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Fraccion arancelaria"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('classification.edit.columns.tariffFraction')"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="classificationData.fraccionArancelariaId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="texto"
                      :options="$store.getters['app/fraccionesArancelarias']"
                      :reduce="option => option.id"
                      :disabled="!permissions || classificationEstado2"
                      @close="() => {
                        classificationData.nicoId = null
                        refetchNicos(classificationData.fraccionArancelariaId)
                      }"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Nico field-->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nico"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('classification.edit.columns.nico')"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="classificationData.nicoId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="texto"
                      :options="nicos"
                      :reduce="option => option.id"
                      :disabled="!permissions || classificationEstado2"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Unidad Medida field-->
              <b-col
                cols="12"
                md="4"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Unidad medida"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('classification.edit.columns.unitMeasure')"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      v-model="classificationData.unidadMedidaId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="false"
                      label="texto"
                      :options="unidadesMedida"
                      :reduce="option => option.id"
                      :disabled="!permissions || classificationTipo1"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <!--Operation Type field-->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.operationType')"
                >
                  <v-select
                    v-model="classificationData.operacionTipoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="operationTypes"
                    :reduce="option => option.id"
                    :disabled="!permissions|| classificationTipo1"
                    @close="refetchDocumentTypes(classificationData.operacionTipoId)"
                  />
                </b-form-group>
              </b-col>
              <!--DocumentType field-->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.documentKey')"
                >
                  <v-select
                    v-model="classificationData.documentoClaveId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="documentTypes"
                    :reduce="option => option.id"
                    :disabled="!permissions || classificationTipo1"
                  />
                </b-form-group>
              </b-col>
              <!--Material type field-->
              <b-col
                cols="12"
                md="4"
              >
                <b-form-group
                  :label="$t('classification.edit.materialType')"
                >
                  <v-select
                    v-model="classificationData.parteMaterialTipoId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="texto"
                    :options="materialTypes"
                    :reduce="option => option.id"
                    :disabled="!permissions|| classificationTipo1"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <app-collapse
              v-if="classificationData.parteEditarId"
            >
              <app-collapse-item :title="$t('Routes.logbookPart')">
                <PartEditTabLog :part-id="classificationData.parteEditarId" />
              </app-collapse-item>
            </app-collapse>
            <b-button
              v-if="permissions && classificationGerenteOperativo"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
              :disabled="classificacionEditing"
            >
              <b-spinner
                v-if="classificacionEditing"
                small
                class="mr-1"
              />
              <feather-icon icon="LayersIcon" />
              {{ $t('classification.edit.classify') }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <conversation ref="refConversation" />
    <b-card>
      <b-card-header>
        <strong>
          <feather-icon icon="BookIcon" />
          {{ $t('classification.edit.classifierNotes') }}
        </strong>
      </b-card-header>
      <b-card-body>
        <div id="richTextInput" />
        <b-button
          v-if="permissions && classificationClasificadorExterno"
          variant="primary"
          class="mt-1"
          @click="onSaveNotes"
        >
          <feather-icon icon="SaveIcon" />
          {{ $t('classification.edit.saveNotes') }}
        </b-button>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { computed, ref } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import classificationService from '@/services/classification.service'
import optionService from '@/services/option.service'
import { saveAs } from 'file-saver'
import { createEditor } from 'vueditor'
import i18n from '@/libs/i18n'
import Conversation from '@/components/Conversation.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import PartEditTabLog from '@/views/apps/part/parts-edit/PartEditTabLog.vue'
import common from '@/libs/common'
import { can } from '@core/libs/acl/utils'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BSpinner,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    Conversation,

    AppCollapse,
    AppCollapseItem,
    PartEditTabLog,
  },
  setup() {
    /* Configs */
    const richTextEditconfig = {
      // toolbar: [
      //   'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor',
      // ],
      fontName: [
        { val: 'arial black' },
        { val: 'times new roman' },
        { val: 'Courier New' },
      ],
      fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
      uploadUrl: '',
    }
    /* Services */
    const {
      updateClassification,
      fetchClassification,
      fetchClassificationFile,
      partialUpdateClassificationNotes,
    } = classificationService()
    const {
      fetchCustomers,
      fetchVendors,
      fetchNicos,
      fetchUnidadesMedida,
      fetchOperationTypes,
      fetchDocumentTypes,
      fetchPartMaterialTypes,
      fetchClassificationRequestTypes,
    } = optionService()

    const { userIsInRole } = common()
    /* UI */
    const classificacionEditing = ref(false)
    /* Data */
    const refRichEdit = ref(null)
    const refConversation = ref(null)
    const classificationData = ref({})

    const customers = ref([])
    fetchCustomers(data => {
      customers.value = data
    })
    const vendors = ref([])
    const refetchVendors = clienteId => {
      if (clienteId) {
        fetchVendors(data => {
          vendors.value = data
        }, clienteId)
      }
    }
    const nicos = ref([])
    const refetchNicos = fraccionArancelariaId => {
      if (fraccionArancelariaId) {
        fetchNicos(data => {
          nicos.value = data
        }, fraccionArancelariaId)
      }
    }
    const unidadesMedida = ref([])
    fetchUnidadesMedida(data => {
      unidadesMedida.value = data
    })

    const operationTypes = ref([])
    fetchOperationTypes(data => {
      operationTypes.value = data
    })

    const documentTypes = ref([])
    const refetchDocumentTypes = operationTypeId => {
      if (operationTypeId) {
        fetchDocumentTypes(operationTypeId, data => {
          documentTypes.value = data
        })
      }
    }
    const materialTypes = ref([])
    fetchPartMaterialTypes(data => {
      materialTypes.value = data
    })

    const requestTypes = ref([])
    fetchClassificationRequestTypes(data => {
      requestTypes.value = data
    })

    fetchClassification(router.currentRoute.params.solicitudId, data => {
      classificationData.value = data
      refetchNicos(classificationData.value.fraccionArancelariaId)
      refetchVendors(classificationData.value.clienteId)
      refetchDocumentTypes(classificationData.value.operacionTipoId)

      refConversation.value.refetchData(classificationData.value.conversacionId)

      const editor = createEditor('#richTextInput', richTextEditconfig)
      editor.setContent(classificationData.value.notasClasificador ? classificationData.value.notasClasificador : '')
      refRichEdit.value = editor
    })
    const partAlreadyExists = ref(false)
    const filesTableFields = [
      {
        key: 'archivo',
        label: i18n.t('classification.edit.labelArchive'),
      },
      {
        key: 'extension',
        label: i18n.t('classification.edit.labelType'),
      },
      {
        key: 'actions',
        label: i18n.t('classification.edit.labelDownload'),
      },
    ]
    /* Validations */
    const clienteData = ref(JSON.parse(JSON.stringify(classificationData.value)))
    const resetclienteData = () => {
      clienteData.value = JSON.parse(JSON.stringify(classificationData))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)
    /* UI */

    /* Events */
    const onSubmit = () => {
      updateClassification(classificationData.value)
        .then(() => {
          router.push({ name: 'apps-classification-list' })
          classificacionEditing.value = false
        })
    }

    const onDownloadFile = item => {
      fetchClassificationFile(item.solicitudId, item.solicitudArchivoId, data => {
        const file = new File([data], `${item.archivo}.${item.extension}`)
        saveAs(file, `${item.archivo}.${item.extension}`)
      })
    }

    const onSaveNotes = () => {
      partialUpdateClassificationNotes(classificationData.value.solicitudId, refRichEdit.value.getContent() ? refRichEdit.value.getContent() : '')
    }

    const permissions = can('write', 'Clasificaciones')

    const classificationClasificadorExterno = computed(() => classificationData.value.clasificacionSolicitudEstadoId === 1 || (classificationData.value.clasificacionSolicitudEstadoId === 3 && userIsInRole('ClasificadorExterno')))
    const classificationGerenteOperativo = computed(() => (classificationData.value.clasificacionSolicitudTipoId === 1 && classificationData.value.clasificacionSolicitudEstadoId === 1) || (classificationData.value.clasificacionSolicitudTipoId === 1 && classificationData.value.clasificacionSolicitudEstadoId === 3 && userIsInRole('ClasificadorExterno')) || (classificationData.value.clasificacionSolicitudTipoId === 2 && classificationData.value.clasificacionSolicitudEstadoId === 1 && userIsInRole('GerenteOperativo')))
    const classificationTipo1 = computed(() => (classificationData.value.clasificacionSolicitudEstadoId === 3 && !userIsInRole('ClasificadorExterno')) || classificationData.value.clasificacionSolicitudEstadoId === 2 || classificationData.value.clasificacionSolicitudTipoId === 1 || (classificationData.value.clasificacionSolicitudEstadoId === 1 && !userIsInRole('GerenteOperativo')))
    const classificationEstado2 = computed(() => (classificationData.value.clasificacionSolicitudEstadoId === 3 && !userIsInRole('ClasificadorExterno')) || classificationData.value.clasificacionSolicitudEstadoId === 2 || (classificationData.value.clasificacionSolicitudTipoId === 2 && !userIsInRole('GerenteOperativo')))

    return {
      // Data
      classificationData,
      customers,
      vendors,
      nicos,
      unidadesMedida,
      operationTypes,
      documentTypes,
      materialTypes,
      requestTypes,
      // Events
      refetchVendors,
      refetchNicos,
      refetchDocumentTypes,
      onSubmit,
      onDownloadFile,
      onSaveNotes,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
      // UI
      partAlreadyExists,
      filesTableFields,
      refRichEdit,
      refConversation,
      classificacionEditing,
      userIsInRole,
      // Computed
      classificationClasificadorExterno,
      classificationGerenteOperativo,
      classificationTipo1,
      classificationEstado2,
      permissions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
