<template>
  <app-timeline>
    <app-timeline-item
      v-for="item in partsLogs"
      :key="item.parteBitacoraId"
      :variant="resolveVariant(item.evento)"
    >
      <div>
        {{ item.evento }}
      </div>
      <div>
        {{ item.observaciones }} -
        <router-link
          v-if="item.solicitudId"
          class="cursor-pointer text-primary"
          :to="{ name: 'apps-classification-edit', params: { solicitudId: item.solicitudId } }"
          tag="div"
        >
          <feather-icon icon="GitPullRequestIcon" />
          {{ $t('AppPart.goSourceRequest') }}
        </router-link>
      </div>
      <div>
        {{ item.fechaHora }}
      </div>
      <div>
        {{ item.usuario }}
      </div>
      <div
        v-if="item.tieneArchivosAdjuntos"
      >
        <span
          class="align-bottom cursor-pointer text-primary"
          @click="downloadFiles(item.solicitudId)"
        >
          <feather-icon icon="PaperclipIcon" />
          {{ $t('AppPart.fileAttachments') }}
        </span>
      </div>
    </app-timeline-item>
  </app-timeline>
</template>

<script>
import { ref } from '@vue/composition-api'
import { saveAs } from 'file-saver'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import partService from '@/services/part.service'
import i18n from '@/libs/i18n'
import classificationService from '@/services/classification.service'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    partId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { fetchPartLog } = partService()
    const { fetchClassificationFiles } = classificationService()
    const partsLogs = ref([])

    fetchPartLog(props.partId, data => {
      partsLogs.value = data
    })
    const resolveVariant = event => {
      if (event === 'ALTA PARTE') return 'primary'
      if (event === 'CAMBIO DESCRIPCION') return 'warning'
      if (event === 'CAMBIO FRACCION') return 'info'
      if (event === 'CAMBIO NICO') return 'secondary'
      if (event === 'CAMBIO ClaveDocumento') return 'primary'
      if (event === 'CAMBIO TIPO MATERIAL') return 'warning'
      if (event === 'CAMBIO UNIDAD DE MEDIDA') return 'info'
      if (event === 'CAMBIO PESO') return 'secondary'
      if (event === 'CAMBIO PROVEEDOR') return 'info'
      if (event === 'CAMBIO TIPO OPERACION') return 'secondary'
      return ''
    }

    /* Events */
    const downloadFiles = solicitudId => {
      fetchClassificationFiles(solicitudId, data => {
        const file = new File([data], `${i18n.t('AppPart.request')}_${solicitudId}_${i18n.t('AppPart.showFilesRecords')}.zip`)
        saveAs(file, `${i18n.t('AppPart.request')}_${solicitudId}_${i18n.t('AppPart.showFilesRecords')}.zip`)
      })
    }

    return {
      partsLogs,

      resolveVariant,

      // Events
      downloadFiles,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
