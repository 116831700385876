import axios from '@axios'
import common from '@/libs/common'

export default function customerService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  // General ----------------------------------------
  const fetchClassifications = (data, callback) => {
    axios
      .get(`${API_URL}/Clasificaciones/Solicitud`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchClassification = (idSolicitud, callback) => {
    axios
      .get(`${API_URL}/Clasificaciones/Solicitud/${idSolicitud}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const updateClassification = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Clasificaciones/Solicitud/${data.solicitudId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const createClassification = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Clasificaciones/Solicitud`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })

  const deleteClassification = (solicitudId, callback) => {
    axios
      .delete(`${API_URL}/Clasificaciones/Solicitud/${solicitudId}`)
      .then(response => {
        throwSuccess(response)
        callback(response)
      })
      .catch(error => {
        throwError(error)
      })
  }

  const fetchClassificationFile = (idSolicitud, idArchivo, callback) => {
    axios
      .get(`${API_URL}/Clasificaciones/Solicitud/${idSolicitud}/Archivo/ ${idArchivo}`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchClassificationFiles = (idSolicitud, callback) => {
    axios
      .get(`${API_URL}/Clasificaciones/Solicitud/${idSolicitud}/Archivos`, { responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        throwError(error)
      })
  }

  const partialUpdateClassificationNotes = (solicitudId, notasClasificador) => new Promise((resolve, reject) => {
    axios
      .patch(`${API_URL}/Clasificaciones/Solicitud/${solicitudId}`, { notasClasificador })
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject(error)
        throwError(error)
      })
  })

  const fetchExportExcel = (data, callback) => {
    axios
      .get(`${API_URL}/Clasificaciones/ExportarExcel`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchExportTemplate = (data, callback) => {
    axios
      .get(`${API_URL}/Clasificaciones/ExportarExcelMasterItem`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createImportExcel = (data, callback, errorCallback) => {
    axios
      .post(`${API_URL}/Clasificaciones/ImportarExcel`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        errorCallback(error.response.data)
      })
  }

  return {
    fetchClassifications,
    fetchClassification,
    updateClassification,
    createClassification,
    deleteClassification,
    fetchClassificationFile,
    fetchClassificationFiles,
    partialUpdateClassificationNotes,
    fetchExportExcel,
    fetchExportTemplate,
    createImportExcel,
  }
}
